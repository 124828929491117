<script setup>
import { computed as vueComputed, reactive as vueReactive, ref as vueRef } from 'vue';
import { watchDebounced as vueuseWatchDebounced } from '@vueuse/core';
import { wTrans as laravelvuei18nWTrans } from 'laravel-vue-i18n';
import { useConfirm as primevueUseConfirm } from 'primevue/useconfirm';
import { PrimeIcons as primevuePrimeIcons } from 'primevue/api';
import { default as ziggyRoute } from '@/../../vendor/tightenco/ziggy';
import { router as inertiaRouter, usePage as inertiaUsePage, Head as InertiaHead } from '@inertiajs/vue3';
import { default as PrimevueButton } from 'primevue/button';
import { default as PrimevueCard } from 'primevue/card';
import { default as PrimevueConfirmDialog } from 'primevue/confirmdialog';
import { default as PrimevueInputText } from 'primevue/inputtext';
import { default as PrimevueToolbar } from 'primevue/toolbar';
import { default as ExhisharePathBar } from '@/Components/exhishare/PathBar.vue';
import { default as ExhisharePaginatedTable } from '@/Components/exhishare/PaginatedTable.vue';
import { default as ExhishareFileViewer } from '@/Components/exhishare/FileViewer.vue';
import { default as ExhishareDownloadSpinner } from '@/Components/exhishare/DownloadSpinner.vue';
import { default as ExhishareUploadButton } from '@/Components/exhishare/UploadButton.vue';
import { default as ExhishareMainLayout } from '@/Layouts/exhishare/Main.vue';

// constants
const modes = {
	'VIEWING': 'viewing',
	'DOWNLOADING': 'downloading',
	'UPLOADING': 'uploading',
	'RENAMING': 'renaming',
	'DELETING': 'deleting',
	'FOLDER': 'folder',
	'DEFAULT': 'default',
};

// properties and emits
const props = defineProps({
	'errors': {
		'type': Object,
		'default': {},
	},
	'fileshare': {
		'type': Object,
		'default': {
			'name': '',
			'path': null,
			'pathlinks': [],
			'uploadsize': 0,
			'internal': false,
			'advanced': false,
		},
	},
	'files': {
		'type': Object,
		'default': {
			'data': [],
			'links': {},
		},
	},
});
const emits = defineEmits([
]);

// variables
const state = vueReactive({
	'mode': modes.DEFAULT,
	'upd': 0,
	'selected': [],
	'canDownload': false,
	'linkdl': '#',
	'iframesrc': 'about:blank',
	'params': ziggyRoute().params,
	'activeItem': {},
	'newitemname': '',
});
const filetable = vueReactive({
	'settings': vueComputed(function () {
		return {
			'sortmode': 'single',
			'perpageopts': props.fileshare.per_page_opts,
		};
	}),
	'columns': [{
		'field': 'name',
		'label': laravelvuei18nWTrans('Name'),
		'sortable': true,
		'sort': null,
	}, {
		'field': 'mtime',
		'label': laravelvuei18nWTrans('Date'),
		'sortable': true,
		'sort': null,
	}, {
		'field': 'size',
		'label': laravelvuei18nWTrans('Size'),
		'sortable': false,
		'sort': null,
		'callback': function (v, f) {
			return (v['isdir']) ? '' : formatSize(v[f]);
		},
	}],
	'menuItems': vueRef([
		{
			'label': laravelvuei18nWTrans('View'),
			'icon': primevuePrimeIcons.EYE,
			'command': function () {
				state.mode = modes.VIEWING;
				return true;
			},
			'disabled': function () {
				return !isViewable(state.activeItem.type);
			},
		}, {
			'label': laravelvuei18nWTrans('Rename'),
			'icon': primevuePrimeIcons.FILE_EDIT,
			'command': function () {
				return confirmRename();
			},
			'visible': vueComputed(function () {
				return props.fileshare.advanced;
			}),
		}, {
			'label': laravelvuei18nWTrans('Delete'),
			'icon': primevuePrimeIcons.TIMES,
			'command': function () {
				return confirmDelete();
			},
			'visible': vueComputed(function () {
				return props.fileshare.advanced;
			}),
		},
	]),
	'items': vueComputed(function () {
		return props.files;
	}),
});
const confirm = primevueUseConfirm();

// functions
const isViewable = function (type) {
	return _.includes([ 'PDF', 'JPG', 'JPEG', 'PNG', 'SVG' ], type);
};
const isViewing = function () {
	return (state.mode == modes.VIEWING);
};
const isDownloading = function () {
	return (state.mode == modes.DOWNLOADING);
};
const isUploading = function () {
	return (state.mode == modes.UPLOADING);
};
const isRenaming = function () {
	return (state.mode == modes.RENAMING);
};
const isMakingFolder = function () {
	return (state.mode == modes.FOLDER);
};
const needsName = function () {
	return (isRenaming() || isMakingFolder());
};
const getPage = function () {
	return {
		'route': ziggyRoute().current(),
		'params': { 'id': props.fileshare.id, },
		'name': props.fileshare.name,
	};
};
const downloadRoute = function (id, path, items, view) {
	return ziggyRoute((_.defaultTo(view, false)) ? 'share.view' : 'share.download', {
		'id': id,
		'path': path,
		'items': items,
	});
};
const uploadRoute = function (params) {
	return ziggyRoute('share.file-upload', params);
};
const renameRoute = function (id, name, to) {
	return ziggyRoute('share.file-namechg', {
		'id': id,
		'path': _.defaultTo(state.params.path, ''),
		'name': name,
		'to': to,
	});
};
const deleteRoute = function (id, name) {
	return ziggyRoute('share.file-erase', {
		'id': id,
		'path': _.defaultTo(state.params.path, ''),
		'name': name,
	});
};
const newFolderRoute = function (id, name) {
	return ziggyRoute('share.new-folder', {
		'id': id,
		'path': _.defaultTo(state.params.path, ''),
		'name': name,
	});
};
const renameActiveFile = function () {
	inertiaRouter.visit(renameRoute(state.params.id, state.activeItem.name), {
		'method': 'put',
		'data': {
			'to': state.newitemname,
		},
		'onSuccess': function (page) {
			state.mode = modes.DEFAULT;
			return true;
		},
		'onError': function (page) {
			state.mode = modes.DEFAULT;
			return true;
		},
	});
	return true;
};
const deleteActiveFile = function () {
	inertiaRouter.visit(deleteRoute(state.params.id, state.activeItem.name), {
		'method': 'delete',
		'onSuccess': function (page) {
			state.mode = modes.DEFAULT;
			return true;
		},
		'onError': function (error) {
			state.mode = modes.DEFAULT;
			return true;
		},
	});
	return true;
};
const createNewFolder = function () {
	inertiaRouter.visit(newFolderRoute(state.params.id), {
		'method': 'put',
		'data': {
			'name': state.newitemname,
		},
		'onSuccess': function (page) {
			state.mode = modes.DEFAULT;
			return true;
		},
		'onError': function (page) {
			state.mode = modes.DEFAULT;
			return true;
		},
	});
	return true;
};
const confirmRename = function () {
	state.mode = modes.RENAMING;
	state.newitemname = state.activeItem.name;
	confirm.require({
		'message': laravelvuei18nWTrans('Please enter the new name for the item'),
		'header': laravelvuei18nWTrans('Rename item'),
		'icon': primevuePrimeIcons.INFO_CIRCLE,
		'acceptLabel': laravelvuei18nWTrans('OK'),
		'rejectLabel': laravelvuei18nWTrans('Cancel'),
		'accept': function () {
			return renameActiveFile();
		},
		'reject': function () {
			return false;
		},
	});
	return true;
};
const confirmDelete = function () {
	state.mode = modes.DELETING;
	confirm.require({
		'message': laravelvuei18nWTrans('Are you sure you want to delete this item?'),
		'header': laravelvuei18nWTrans('Delete item'),
		'icon': primevuePrimeIcons.EXCLAMATION_TRIANGLE,
		'acceptLabel': laravelvuei18nWTrans('OK'),
		'rejectLabel': laravelvuei18nWTrans('Cancel'),
		'acceptClass': 'p-button-danger',
		'accept': function () {
			return deleteActiveFile();
		},
		'reject': function () {
			return false;
		},
	});
	return true;
};
const confirmNewFolder = function () {
	state.mode = modes.FOLDER;
	confirm.require({
		'message': laravelvuei18nWTrans('Please enter the new folder name'),
		'header': laravelvuei18nWTrans('New folder'),
		'icon': primevuePrimeIcons.INFO_CIRCLE,
		'acceptLabel': laravelvuei18nWTrans('OK'),
		'rejectLabel': laravelvuei18nWTrans('Cancel'),
		'accept': function () {
			return createNewFolder();
		},
		'reject': function () {
			return false;
		},
	});
	return true;
};
const getSelectedCount = function () {
	return (state.selected.length > 0) ? _.toString(state.selected.length) : '';
};
const refresh = function () {
	state.upd++;
	return true;
};

// event handlers
const onUpdatePage = function (e) {
	_.unset(state.params, 'page');
	state.params['page'] = e;
	return refresh();
};
const onUpdateSort = function (e) {
	_.forEach([ 'dir', 'sort' ], function (v) {
		_.unset(state.params, v);
		return true;
	});
	_.forEach(e, function (v, k) {
		state.params['sort'] = k;
		state.params['dir'] = v;
		return true;
	});
	return refresh();
};
const onUpdateSelect = function (e) {
	state.selected = e;
	state.canDownload = (e.length > 0);
	state.linkdl = (e.length > 0) ? downloadRoute(
		state.params.id, state.params.path, state.selected
	) : '#';
	return true;
};
const onUpdateActive = function (e) {
	state.activeItem = _.set(e, 'viewlnk', downloadRoute(
		state.params.id, state.params.path, [ e.hash ], true
	));
	return true;
};
const onUpdateRows = function (e) {
	state.params.per_page = e;
	return refresh();
};
const onViewerVisibilityChange = function (e) {
	state.mode = (e.visible) ? modes.VIEWING : modes.DEFAULT;
	return true;
};
const onUploaderVisibilityChange = function (e) {
	state.mode = (e.visible) ? modes.UPLOADING : modes.DEFAULT;
	return (state.mode == modes.DEFAULT) ? refresh() : true;
};
const onSpinnerVisibilityChange = function (e) {
	state.mode = (e.visible) ? modes.DOWNLOADING : modes.DEFAULT;
	if (e.visible)
		return true;

	// Wait a bit here to prevent the iframe's src being reset before download starts
	setTimeout(function () {
		state.iframesrc = 'about:blank';
	}, constants.DEBOUNCE_TIMEOUT);
	return true;
};
const onDownloadClicked = function (e) {
	state.spinnervisible = true;
	state.mode = modes.DOWNLOADING;
	state.iframesrc = state.linkdl;
	return true;
};
const onUploadClicked = function (e) {
	state.mode = modes.UPLOADING;
	return true;
};
const onFolderClicked = function (e) {
	return confirmNewFolder();
};
const onLayoutParamsChange = function (e) {
	_.merge(state.params, e);
	return true;
};
const onRefresh = function (e) {
	return refresh();
};

// page events
vueuseWatchDebounced(function () {
	return state.upd;
}, function (cur, old) {
	let thisroute = ziggyRoute(ziggyRoute().current(), state.params)
	inertiaRouter.get(thisroute, {
	}, {
		'preserveState': true,
	});
	return true;
}, {
	'deep': true,
	'debounce': constants.DEBOUNCE_TIMEOUT,
	'maxWait': constants.DEFAULT_TIMEOUT,
});
</script>

<template>
	<InertiaHead title="Folder" />
	<ExhishareMainLayout v-bind:advanced="props.fileshare.advanced" v-bind:page="getPage()" v-bind:internal="props.fileshare.internal" v-bind:title="props.fileshare.name" v-on:update:params="onLayoutParamsChange($event)">
		<form action="#" method="POST">
			<ExhisharePathBar v-bind:pathlinks="props.fileshare.pathlinks" />
			<ExhishareUploadButton v-bind:visible="isUploading()" v-bind:uploadsize="props.fileshare.uploadsize" v-on:update:visible="onUploaderVisibilityChange($event)" />
			<ExhishareDownloadSpinner v-bind:visible="isDownloading()" v-on:update:visible="onSpinnerVisibilityChange($event)" />
			<ExhishareFileViewer v-bind:visible="isViewing()" v-bind:item="state.activeItem" v-on:update:visible="onViewerVisibilityChange($event)" />
			<PrimevueConfirmDialog>
				<template #message="slotProps">
					<span class="p-confirm-dialog-icon" v-bind:class="slotProps.message.icon"></span>
					<div class="p-confirm-dialog-message">
						<div class="pt-3">{{ slotProps.message.message }}</div>
						<div class="pt-3">
							<PrimevueInputText type="text" v-model="state.newitemname" v-if="needsName()" />
						</div>
					</div>
				</template>
			</PrimevueConfirmDialog>
			<PrimevueToolbar class="m-2">
				<template #start>
					<PrimevueButton class="mr-2" severity="primary" size="small" badgeClass="p-badge-success" v-bind:badge="getSelectedCount()" v-bind:disabled="!state.canDownload" v-bind:icon="primevuePrimeIcons.DOWNLOAD" v-bind:label="$t('Download')" v-on:click="onDownloadClicked($event)" />
					<PrimevueButton class="mr-2" severity="secondary" size="small" v-bind:icon="primevuePrimeIcons.UPLOAD" v-bind:label="$t('Upload')" v-on:click="onUploadClicked($event)" />
					<PrimevueButton class="mr-2" severity="secondary" size="small" v-bind:icon="primevuePrimeIcons.REFRESH" v-bind:label="$t('Refresh')" v-on:click="onRefresh($event)" />
					<PrimevueButton class="mr-2" severity="secondary" size="small" v-bind:icon="primevuePrimeIcons.FOLDER" v-bind:label="$t('New folder')" v-on:click="onFolderClicked($event)" v-if="props.fileshare.advanced" />
				</template>
				<template #end>
				</template>
			</PrimevueToolbar>
			<PrimevueCard class="m-2">
				<template #content>
					<iframe class="hidden" height="1" width="1" v-bind:src="state.iframesrc"></iframe>
					<ExhisharePaginatedTable v-bind:settings="filetable.settings" v-bind:columns="filetable.columns" v-bind:items="filetable.items" v-bind:menuItems="filetable.menuItems" v-on:update:page="onUpdatePage($event)" v-on:update:sort="onUpdateSort($event)" v-on:update:select="onUpdateSelect($event)" v-on:update:active="onUpdateActive($event)" v-on:update:rows="onUpdateRows($event)" />
				</template>
			</PrimevueCard>
		</form>
	</ExhishareMainLayout>
</template>
