<script setup>
import { computed as vueComputed, reactive as vueReactive, onMounted as vueOnMounted } from 'vue';
import { useStorage as vueuseUseStorage } from '@vueuse/core';
import { PrimeIcons as primevuePrimeIcons } from 'primevue/api';
import { default as ziggyRoute } from '@/../../vendor/tightenco/ziggy';
import { router as inertiaRouter } from '@inertiajs/vue3';
import { default as PrimevueButton } from 'primevue/button';
import { default as PrimevueDialog } from 'primevue/dialog';
import { default as PrimevueListbox } from 'primevue/listbox';

// constants
const dialogPT = {
	'header': {
		'class': '-mb-1',
	},
};

// properties and emits
const props = defineProps({
	'visible': {
		'type': Boolean,
		'default': false,
	},
	'page': {
		'type': Object,
		'default': {},
	},
});
const emits = defineEmits([
	'update:visible',
]);

// variables
const state = vueReactive({
	'selectedItem': null,
	'hasSelectedItem': vueComputed(function () {
		return !_.isNil(state.selectedItem);
	}),
	'visible': vueComputed({
		'get': function () {
			return props.visible;
		},
		'set': function (v) {
			emits('update:visible', v);
			return true;
		},
	}),
});
const history = vueuseUseStorage('share-history', [], localStorage, {
	'mergeDefaults': true,
});
const reverseHistory = vueComputed(function () {
	return _.orderBy(history.value, [ 'date', ], [ 'desc', ]);
});

// functions
const formatDate = function (d) {
	return (new Date(d)).toLocaleDateString();
};
const getVSOpts = function () {
	return {
		'itemSize': 42, // 20 height  + 2*12 padding
	};
};
const compareWithLast = function (field) {
	return _.isEqual(_.get(_.last(history.value), field), _.get(props.page, field));
};

// event handlers
const onGoButtonPushed = function (e) {
	let entry = _.find(history.value, { 'id': state.selectedItem, });
	if (_.isNil(entry))
		return false;
	inertiaRouter.get(ziggyRoute(entry.route, entry.params));
	return true;
};
const onClearButtonPushed = function (e) {
	state.selectedItem = null;
	history.value = [];
	return true;
};

// page events
vueOnMounted(function () {
	if (compareWithLast('route') && compareWithLast('params'))
		return false;
	let entry = {
		'id': _.random(0, 1048575),
		'route': _.defaultTo(props.page.route, ziggyRoute().current()),
		'params': _.defaultTo(props.page.params, {}),
		'name': _.defaultTo(props.page.name, '...'),
		'date': _.now(),
	};
	history.value = _.concat(history.value, [ entry, ]);
	return true;
});
</script>

<template>
	<PrimevueDialog class="w-6" v-bind:pt="dialogPT" v-bind:header="$t('History')" v-bind:modal="true" v-model:visible="state.visible">
		<PrimevueListbox listStyle="height: 15rem;" optionLabel="name" optionValue="id" v-bind:emptyMessage="$t('History is empty')" v-bind:filter="true" v-bind:options="reverseHistory" v-bind:virtualScrollerOptions="getVSOpts()" v-model="state.selectedItem">
			<template #option="slotProps">
				<div class="flex relative">
			            <div class="">{{ slotProps.option.name }}</div>
			            <div class="absolute right-0 p-text-secondary">{{ formatDate(slotProps.option.date) }}</div>
				</div>
			</template>
		</PrimevueListbox>
		<div class="text-right mt-2">
			<PrimevueButton class="mr-2" severity="danger" size="small" v-bind:icon="primevuePrimeIcons.TIMES" v-bind:label="$t('Clear')" v-bind:text="true" v-on:click="onClearButtonPushed($event)" />
			<PrimevueButton class="mr-0" severity="primary" size="small" v-bind:disabled="!state.hasSelectedItem" v-bind:icon="primevuePrimeIcons.PLAY" v-bind:label="$t('Go')" v-on:click="onGoButtonPushed($event)" />
		</div>
	</PrimevueDialog>
</template>
