<script setup>
import { ref as vueRef, reactive as vueReactive } from 'vue';
import { PrimeIcons as primevuePrimeIcons } from 'primevue/api';
import { router as inertiaRouter, Link as inertiaLink } from '@inertiajs/vue3';
import { default as PrimevueButton } from 'primevue/button';
import { default as PrimevueColumn } from 'primevue/column';
import { default as PrimevueContextMenu } from 'primevue/contextmenu';
import { default as PrimevueDataTable } from 'primevue/datatable';
import { default as PrimevueMenu } from 'primevue/menu';

// properties and emits
const props = defineProps({
	'settings': {
		'type': Object,
		'default': {
			'sortmode': 'multiple',
			'perpageopts': [ 5, ],
		},
		'contextmenu': false,
	},
	'columns': {
		'type': Array,
		'default': [],
	},
	'menuItems': {
		'type': Array,
		'default': [],
	},
	'items': {
		'type': Object,
		'default': {
			'data': [],
			'links': {},
		},
	},
});
const emits = defineEmits([
	'update:page',
	'update:sort',
	'update:select',
	'update:active',
	'update:rows',
]);

// variables
const state = vueReactive({
	'loading': false,
	'lazyParams': {},
	'activeItem': {},
	'sort': {}, // FIXME order
	'selected': [],
	'allChecked': false,
});
const m = vueRef(), cm = vueRef(), dt = vueRef();

// functions
const getColumn = function (field) {
	return _.find(props.columns, { 'field': field, });
};
const canBeLinked = function (slotProps) {
	return (slotProps.field == 'name' && _.defaultTo(slotProps.data.link, '').length > 0);
};
const canBeVisited = function (slotProps) {
	return (canBeLinked(slotProps) && _.defaultTo(slotProps.data.inertia, false) == true);
};
const hasTransform = function (slotProps) {
	return !_.isNil(getColumn(slotProps.field)['callback'], null);
};
const emitSelection = function () {
	emits('update:select', _.map(state.selected, function (v) {
		return v.hash;
	}));
	return true;
};
const emitActive = function () {
	emits('update:active', state.activeItem);
	return true;
};
const emitRows = function (rows) {
	emits('update:rows', rows);
	return true;
};

// event handlers
const onToggleMenu = function (e, slotProps) {
	state.activeItem = slotProps.data;
	emitActive();
	m.value.toggle(e);
	return true;
};
const onRowContextMenu = function (e) {
	if (!props.settings.contextmenu)
		return false;
	emitActive();
	cm.value.show(e.originalEvent);
	return true;	
};
const onPage = function (e) {
	state.lazyParams = e;
	emits('update:page', state.lazyParams.page + 1);
	return true;
};
const onSort = function (e) {
	state.lazyParams = e;
	let sort = (props.settings.sortmode == "single") ? [] : e.multiSortMeta;
	state.sort = {};
	if (props.settings.sortmode == 'single' && !_.isNil(e.sortField))
		sort[0] = { 'field': e.sortField, 'order': e.sortOrder, };
	_.forEach(sort, function (v) {
		state.sort[v.field] = (v.order < 0) ? 'desc' : 'asc';
		return true;
	});
	emits('update:sort', state.sort);
	return true;
};
const onRowsChanged = function (e) {
	return emitRows(e);
};
const onSelectionChanged = function (e) {
	state.allChecked = (state.selected.length == props.items.length);
	return emitSelection();
};
const onSelectAll = function (e) {
	state.allChecked = e.checked;
	state.selected = (state.allChecked) ? props.items.data : [];
	return emitSelection();
};
</script>

<template>
	<PrimevueContextMenu ref="cm" v-bind:model="props.menuItems" v-if="props.settings.contextmenu">
		<template #item="{ item, label, props }">
			<!-- Fix deprecation warning -->
			<a class="flex" v-bind="props.action">
				<span v-bind="props.icon" />
				<span v-bind="props.label">{{ label }}</span>
			</a>
		</template>
	</PrimevueContextMenu>
	<PrimevueMenu id="overlay_menu" ref="m" v-bind:model="props.menuItems" v-bind:popup="true" v-else>
		<template #item="{ item, label, props }">
			<!-- Fix deprecation warning -->
			<a class="flex" v-bind="props.action">
				<span v-bind="props.icon" />
				<span v-bind="props.label">{{ label }}</span>
			</a>
		</template>
	</PrimevueMenu>
	<PrimevueDataTable class="p-datatable-sm text-sm" dataKey="hash" ref="dt" v-bind:contextMenu="props.settings.contextmenu" v-bind:first="props.items.from" v-bind:lazy="true" v-bind:loading="state.loading" v-bind:paginator="true" v-bind:removableSort="true" v-bind:rows="props.items.per_page" v-bind:rowsPerPageOptions="props.settings.perpageopts" v-bind:sortMode="props.settings.sortmode" v-bind:totalRecords="props.items.total" v-bind:value="props.items.data" v-bind:selectAll="state.allChecked" v-model:selection="state.selected" v-model:contextMenuSelection="state.activeItem" v-on:page="onPage($event)" v-on:sort="onSort($event)" v-on:update:rows="onRowsChanged($event)" v-on:row-select="onSelectionChanged($event)" v-on:row-unselect="onSelectionChanged($event)" v-on:select-all-change="onSelectAll($event)" v-on:rowContextmenu="onRowContextMenu($event)">
		<PrimevueColumn selectionMode="multiple"></PrimevueColumn>
		<PrimevueColumn v-bind:field="column.field" v-bind:header="column.label" v-bind:sortable="column.sortable" v-for="column in props.columns">
			<template #body="slotProps">
				<span v-if="canBeVisited(slotProps)"><inertiaLink v-bind:href="slotProps.data.link">{{ slotProps.data[slotProps.field] }}</inertiaLink></span>
				<span v-else-if="canBeLinked(slotProps)"><a v-bind:href="slotProps.data.link">{{ slotProps.data[slotProps.field] }}</a></span>
				<span v-else-if="hasTransform(slotProps)">{{ getColumn(slotProps.field).callback(slotProps.data, slotProps.field) }}</span>
				<span v-else>{{ slotProps.data[slotProps.field] }}</span>
			</template>
		</PrimevueColumn>
		<PrimevueColumn field="" header="">
			<template #body="slotProps">
				<PrimevueButton class="p-0" severity="secondary" size="small" v-bind:icon="primevuePrimeIcons.BARS" v-bind:label="$t('')" v-bind:link="true" v-on:click="onToggleMenu($event, slotProps)" aria-controls="overlay_menu" aria-haspopup="true" />
			</template>
		</PrimevueColumn>
	</PrimevueDataTable>
</template>
