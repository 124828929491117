<script setup>
import { reactive as vueReactive, watch as vueWatch, onMounted as vueOnMounted } from 'vue';
import { useMounted as vueuseUseMounted, watchTriggerable as vueuseWatchTriggerable } from '@vueuse/core';
import { wTrans as laravelvuei18nWTrans } from 'laravel-vue-i18n';
import { useToast as primevueUseToast } from 'primevue/usetoast';
import { ToastSeverity as primevueToastSeverity } from 'primevue/api';
import { default as PrimevueToast } from 'primevue/toast';

// properties and emits
const props = defineProps({
	'messages': {
		'type': Object,
		'default': {
			'flash': [],
			'errors': [],
		},
	},
});
const emits = defineEmits([
        'message:close',
]);

// variables
const state = vueReactive({
});
const toast = primevueUseToast();

// functions
const addToastMessage = function (severity, msg, title, source, id) {
	if (_.defaultTo(title, '').length == 0)
		title = severity;
	toast.add({
		'severity': severity,
		'summary': title,
		'detail': msg,
		'life': 3000,
		'_source': source,
		'_id': id,
	});
	return true;
};
const refreshToastMessages = function () {
	toast.removeAllGroups();
	_.forEach(_.defaultTo(props.messages.flash, []), function (v, k) {
		return addToastMessage(v.severity, v.message, v.title, 'flash', k);
	});
	_.forEach(_.defaultTo(props.messages.errors, []), function (v, k) {
		return addToastMessage(primevueToastSeverity.ERROR, v, laravelvuei18nWTrans('Invalid input'), 'errors', k);
	});
	return true;
};

// XXX Must come after function definitions
const { trigger, ignoreUpdates } = vueuseWatchTriggerable(function () {
	return props.messages
}, function (cur, old) {
	if (!vueuseUseMounted())
		return false;
	return refreshToastMessages();
}, {
	'deep': true,
	'immediate': true,
});

// event handlers
const onToastClose = function (e) {
        emits('message:close', e);
	return true;
};

// page events
vueOnMounted(function () {
	trigger();
	return true;
});
</script>

<template>
	<PrimevueToast v-on:close="onToastClose($event)" />
</template>
