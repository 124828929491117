<script setup>
import { computed as vueComputed, reactive as vueReactive, ref as vueRef } from 'vue';
import { PrimeIcons as primevuePrimeIcons } from 'primevue/api';
import { default as ziggyRoute } from '@/../../vendor/tightenco/ziggy';
import { router as inertiaRouter, useForm as inertiaUseForm } from '@inertiajs/vue3';
import { default as PrimevueButton } from 'primevue/button';
import { default as PrimevueInputText } from 'primevue/inputtext';
import { default as PrimevueOverlayPanel } from 'primevue/overlaypanel';
import { default as PrimevuePassword } from 'primevue/password';

// constants
const loginPT = {
	'root': {
		'autocomplete': 'username',
	},
};
const passIP = {
	'size': 'small',
	'autocomplete': 'current-password',
};

// properties and emits
const props = defineProps({
	'internal': {
		'type': Boolean,
		'default': false,
	},
	'advanced': {
		'type': Boolean,
		'default': false,
	},
});
const emits = defineEmits([
]);

// variables
const state = vueReactive({
	'hasMissingField': vueComputed(function () {
		return (!fieldHasText('login') || !fieldHasText('passw'));
	}),
});
const op = vueRef();
const loginForm = inertiaUseForm({
	'login': '',
	'passw': '',
});

// functions
const loginRoute = function () {
	return ziggyRoute('share.access', { 'id': 'dummy', });
};
const fieldHasText = function (field) {
	return (_.defaultTo(loginForm[field], '').length > 0);
};
const fieldClass = function (field) {
	return (fieldHasText(field)) ? '' : 'p-invalid';
};
const isUnlocked = function () {
	return (props.internal || props.advanced);
};
const getLockIcon = function () {
	return (isUnlocked()) ? primevuePrimeIcons.LOCK_OPEN : primevuePrimeIcons.LOCK;
};
const getLockSeverity = function () {
	return (isUnlocked()) ? 'success' : 'warning';
};

// event handlers
const onToggleLoginPanel = function (e) {
	op.value.toggle(e);
	return true;
};
const onLogin = function (e) {
	op.value.hide();
	loginForm.post(loginRoute());
	loginForm.reset();
	return true;
};
const onLogout = function (e) {
	op.value.hide();
	inertiaRouter.post(loginRoute(), {
		'logout': true,
	});
	return true;
};
</script>

<template>
	<PrimevueButton class="ml-2" v-bind:disabled="props.internal" v-bind:icon="getLockIcon()" v-bind:severity="getLockSeverity()" v-on:click="onToggleLoginPanel($event)" />
	<PrimevueOverlayPanel ref="op">
		<form action="#" method="post">
			<div class="" v-if="!isUnlocked()">
				<div class="field grid">
					<div class="col">
						<PrimevueInputText class="w-full" id="login" size="small" v-bind:pt="loginPT" v-bind:class="fieldClass('login')" v-bind:placeholder="$t('Login')" v-model="loginForm.login" />
					</div>
				</div>
				<div class="field grid">
					<div class="col">
						<PrimevuePassword id="passw" v-bind:class="fieldClass('passw')" v-bind:feedback="false" v-bind:inputProps="passIP" v-bind:placeholder="$t('Password')" v-bind:toggleMask="true" v-model="loginForm.passw" />
					</div>
				</div>
				<PrimevueButton size="small" v-bind:disabled="state.hasMissingField" v-bind:label="$t('Unlock')" v-on:click="onLogin()" />
			</div>
			<div class="" v-else>
				<PrimevueButton size="small" v-bind:label="$t('Lock')" v-on:click="onLogout()" />
			</div>
		</form>
	</PrimevueOverlayPanel>
</template>
