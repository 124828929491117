<script setup>
import { Head as InertiaHead } from '@inertiajs/vue3';
import { default as BreezeGuestLayout } from '@/Layouts/Guest.vue';
</script>

<template>
	<InertiaHead title="Start" />
	<BreezeGuestLayout>
		<template #header>
			<h2 class="font-semibold text-xl text-gray-800 leading-tight">Start</h2>
		</template>
		<div class="py-12">
			<div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
				<div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
					<div class="p-6 bg-white border-b border-gray-200">
						<span>Nothing to see here</span>
					</div>
				</div>
			</div>
		</div>
	</BreezeGuestLayout>
</template>
