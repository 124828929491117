<script setup>
import { reactive as vueReactive } from 'vue';
import { getActiveLanguage as laravelvuei18nGetActiveLanguage, loadLanguageAsync as laravelvuei18nLoadLanguageAsync } from 'laravel-vue-i18n';
import { usePage as inertiaUsePage } from '@inertiajs/vue3';
import { default as PrimevueDropdown } from 'primevue/dropdown';

// properties and emits
const props = defineProps({
	'languages': {
		'type': Array,
		'default': [],
	},
});
const emits = defineEmits([
        'language:select',
]);

// variables
const state = vueReactive({
	'selectedLang': laravelvuei18nGetActiveLanguage(),
});

// functions
const onChange = function (e) {
	state.selectedLang = e.value;
	laravelvuei18nLoadLanguageAsync(state.selectedLang);
        emits('language:select', e);
        return true;
};
</script>

<template>
	<form action="#">
		<PrimevueDropdown optionLabel="name" optionValue="code" v-bind:options="props.languages" v-model="state.selectedLang" v-on:change="onChange($event)" />
	</form>
</template>
