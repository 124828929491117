<script setup>
import { computed as vueComputed, reactive as vueReactive } from 'vue';
import { wTrans as laravelvuei18nWTrans } from 'laravel-vue-i18n';
import { PrimeIcons as primevuePrimeIcons } from 'primevue/api';
import { default as ziggyRoute } from '@/../../vendor/tightenco/ziggy';
import { router as inertiaRouter, useForm as inertiaUseForm } from '@inertiajs/vue3';
import { default as PrimevueButton } from 'primevue/button';
import { default as PrimevueDialog } from 'primevue/dialog';
import { default as PrimevueFileContent } from 'primevue/fileupload/FileContent.vue';
//import { default as PrimevueFileUpload } from 'primevue/fileupload';
import { default as PrimevueFileUpload } from '@/Components/primevue/FileUpload.vue';
import { default as PrimevueMessage } from 'primevue/message';
import { default as PrimevueProgressBar } from 'primevue/progressbar';

// constants
const filesize_msg = laravelvuei18nWTrans('{0}: Invalid file size, file size should be smaller than {1}.').value;
const filename_msg = laravelvuei18nWTrans('{0}: Invalid file name, colons (:) or slashes (/) are forbidden.').value;
const modes = {
	'UPLOADING': 'uploading',
	'DEFAULT': 'default',
};
const dialogPT = {
	'header': {
		'class': '-mb-1',
	},
};
const fileContentPT = {
	'thumbnail': {
		'class': 'hidden',
	},
};
const fileUploadPT = {
	'cancelButton': {
		'root': {
			'class': 'p-button-danger p-button-sm',
		},
	},
	'chooseButton': {
		'class': 'p-button-sm',
	},
	'uploadButton': {
		'root': {
			'class': 'p-button-sm',
		},
	},
};

// properties and emits
const props = defineProps({
	'visible': {
		'type': Boolean,
		'default': false,
	},
	'uploadsize': {
		'type': Number,
		'default': 0,
	},
});
const emits = defineEmits([
	'update:visible',
]);

// variables
const state = vueReactive({
	'mode': modes.DEFAULT,
	'visible': vueComputed({
		'get': function () {
			return props.visible;
		},
		'set': function (v) {
			if (isUploading())
				return false;
			resetFileUpload();
			emits('update:visible', v);
			return true;
		},
	}),
	'params': ziggyRoute().params,
	'templates': {
		'fileremoveicon': false,
	},
	'fileupload': {
		'disabled': vueComputed(function () {
			return (state.filestogo > 0 || isUploading());
		}),
		'uploadedFileCount': vueComputed(function () {
			return state.fileupload.uploadedFiles.length;
		}),
		'files': [],
		'messages': [],
		'progress': vueComputed(function () {
			return (state.uploadedsize * 100) / state.totalsize;
		}),
		'uploadedFiles': [],
	},
	'paths': [],
	'failedFiles': [],
	'filestogo': 0,
	'filestoupload': 0,
	'totalsize': 0,
	'uploadedsize': 0,
	'awaitsize': 0,
});
const titlemsg = vueComputed(function () {
	if (!hasFiles(true, true))
		return laravelvuei18nWTrans('Upload files').value;
	return laravelvuei18nWTrans('Upload files (:act/:tot)', {
		'act': state.fileupload.uploadedFiles.length + state.failedFiles.length,
	       	'tot': state.filestoupload,
	}).value;
});
const uploadform = inertiaUseForm({
	'uploadfile': null,
});

// functions
const uploadRoute = function (params) {
	return ziggyRoute('share.file-upload', { 'id': params.id, 'path': params.path || '', });
};
const progressRoute = function (params) {
	return ziggyRoute('share.progress', { 'id': params.id, 'path': params.path || '', });
};
const isUploading = function () {
	return (state.mode == modes.UPLOADING);
};
const hasFiles = function (includeuploaded, includefailed) {
	if (_.defaultTo(includeuploaded, false) && state.fileupload.uploadedFiles.length > 0)
		return true;
	if (_.defaultTo(includefailed, false) && state.failedFiles.length > 0)
		return true;
	return (state.fileupload.files.length > 0 || isUploading());
};
const resetFileUpload = function () {
	state.fileupload.files = [];
	state.fileupload.messages = [];
	state.fileupload.uploadedFiles = [];
	state.fileupload.failedFiles = [];
	state.paths = [];
	state.filestogo = 0;
	state.filestoupload = 0;
	state.totalsize = 0;
	state.uploadedsize = 0;
	return true;
};
const increaseProgress = function (bytes) {
	state.uploadedsize += bytes;
console.log("progress:" + state.uploadedsize + "/" + state.totalsize + " = " + state.fileupload.progress);
	return true;
};
const pullFileFrom = function (coll, index) {
	// XXX _.pullAt() seems broken on reactive objects...
	let pulled = coll[index];
	coll = _.filter(coll, function (v, k, c) {
		return (k != index);
	});
	return pulled;
};
const setFileUploaded = function (index) {
	state.fileupload.uploadedFiles = _.concat(
		state.fileupload.uploadedFiles,
		_.pullAt(state.fileupload.files, index) // XXX ... but here it's the reverse. How weird!
	);
	return true;
};
const setFileFailed = function (index) {
	state.failedFiles = _.concat(
		state.failedFiles,
		_.pullAt(state.fileupload.files, index)
	);
	return true;
};
const maybeContinue = function (index, inertia) {
	if (index < 0) {
		state.mode = modes.DEFAULT;
		return true;
	}
	return uploadSingleFile(index, _.defaultTo(inertia, false));
};
const uploadSingleFileWithInertia = function (index) {
	state.awaitsize = state.uploadedsize + state.fileupload.files[index].size;
	uploadform.uploadfile = state.fileupload.files[index];
	uploadform.filepath = state.paths[index];
	uploadform.post(uploadRoute(state.params), {
		'onSuccess': function (page) {
			uploadform.reset();
			setFileUploaded(index);
			return maybeContinue(--index, true);
		},
		'onError': function (error) {
			uploadform.reset();
			setFileFailed(index);
			return maybeContinue(--index, true);
		},
		'onProgress': function (progress) {
			return increaseProgress(progress.bytes);
		},
	});
	return true;
};
const uploadSingleFileWithAxios = function (index) {
	var data = new FormData();
	data.append('uploadfile', state.fileupload.files[index]);
	data.append('filepath', state.paths[index]);
	state.awaitsize = state.uploadedsize + (state.fileupload.files[index].size * 2);
	var finished = false, success = false;
	var interval = setInterval(function () {
		if (state.uploadedsize < state.awaitsize / 2)
			return false;
		axios.get(progressRoute(state.params)).then(function (res) {
console.log("transfer:" + res.data.progress.ul.last);
			increaseProgress(res.data.progress.ul.last);
			if (state.uploadedsize < state.awaitsize)
				return false;
			if (!finished)
				return false;
			let fin = (success) ? setFileUploaded(index) : setFileFailed(index);
			clearInterval(interval);
			maybeContinue(--index);
			return true;
		});
		return true;
	}, constants.PROGRESS_INTV);
	axios.post(uploadRoute(state.params), data, {
		'onUploadProgress': function (progressEvent) {
console.log("upload:" + progressEvent.bytes);
			return increaseProgress(progressEvent.bytes);
		},
	}).then(function (res) {
console.log('finished:true');
console.log(res);
		finished = true;
		success = true;
		return true;
	}).catch(function (err) {
console.log('finished:false');
console.log(err);
		state.uploadedsize = state.awaitsize; // force size so we jump to next file
		finished = true;
		success = false;
		return true;
	});
	return true;
};
const uploadSingleFile = function (index, inertia) {
	if (_.defaultTo(inertia, false))
		return uploadSingleFileWithInertia(index);
	return uploadSingleFileWithAxios(index);
};
const addFile = function (f, path) {
console.log("addFile: " + path);
	state.filestogo--;
	if (f.size > props.uploadsize) {
		state.fileupload.messages.push(filesize_msg.replace('{0}', f.name).replace('{1}', props.uploadsize));
		return false;
	}
	if (!_.isNil(f.name.match(/[:\/]/g))) {
		state.fileupload.messages.push(filename_msg.replace('{0}', f.name));
		return false;
	}
	state.paths.push(path);
	state.fileupload.files.push(f);
	state.totalsize += 2 * f.size;
	return true;
};
const scanFiles = function (entry) {
	if (_.isNil(entry))
		return false;
	if (!entry.isDirectory) {
		state.filestogo++;
		entry.file(function (f) {
			state.filestoupload++;
			return addFile(f, _.trimStart(entry.fullPath, '/'));
		});
		return true;
	}
	entry.createReader().readEntries(function (entries) {
		entries.forEach(function (e) {
			scanFiles(e);
			return true;
		});
		return true;
	});
	return true;
};
const getFilesFromDataTransfer = function (e) {
	let entries = e.originalEvent.dataTransfer.items;
	for (let i = 0; i < entries.length; i++) {
		let entry = entries[i].webkitGetAsEntry();
		scanFiles(entry);
	}
	return true;
};
const getFilesFromTarget = function (e) {
	let files = e.originalEvent.target.files;
	state.filestogo = files.length;
	state.filestoupload = files.length;
	for (let i = 0; i < files.length; i++) {
		addFile(files[i], files[i].name);
	}
	return true;
};

// event handlers
const onSelect = function (e) {
	resetFileUpload();
	let result = (e.originalEvent.dataTransfer?.items) ? getFilesFromDataTransfer(e) : getFilesFromTarget(e);
};
const onRemove = function (e, callback) {
	if (isUploading())
		return false;
	pullFileFrom(state.fileupload.files, e);
	callback(e);
	return true;
};
const onRemoveUploaded = function (e, callback) {
	pullFileFrom(state.fileupload.uploadedFiles, e);
	callback(e);
	return true;
};
const onRemoveFailed = function (e) {
	pullFileFrom(state.failedFiles, e);
	return true;
};
const onClear = function (e) {
	if (isUploading())
		return false;
	resetFileUpload();
	return true;
};
const onUploader = function (e) {
	state.fileupload.files = e.files;
	if (state.fileupload.files.length == 0)
		return false;
	state.fileupload.uploadedFiles = [];
	state.uploadedsize = 0;
	state.mode = modes.UPLOADING;
	return uploadSingleFile(state.fileupload.files.length - 1, false);
};
</script>

<template>
	<PrimevueDialog class="w-6 max-h-31rem" v-bind:pt="dialogPT" v-bind:header="titlemsg" v-bind:modal="true" v-model:visible="state.visible">
		<PrimevueFileUpload mode="advanced" name="file[]" url="#" v-bind:pt="fileUploadPT" v-bind:cancelLabel="$t('Clear')" v-bind:chooseLabel="$t('Choose')" v-bind:customUpload="true" v-bind:disabled="state.fileupload.disabled" v-bind:invalidFileSizeMessage="filesize_msg" v-bind:maxFileSize="props.uploadsize" v-bind:multiple="true" v-bind:previewWidth="0" v-bind:uploadLabel="$t('Upload')" v-bind:uploadedFileCount="state.fileupload.uploadedFileCount" v-bind:files="state.fileupload.files" v-bind:messages="state.fileupload.messages" v-bind:progress="state.fileupload.progress" v-bind:uploadedFiles="state.fileupload.uploadedFiles" v-on:select="onSelect($event)" v-on:clear="onClear($event)" v-on:uploader="onUploader($event)">
			<template #empty>
				<span v-if="!hasFiles(true, true)">{{ $t('You can drop your files here.') }}</span>
			</template>
			<template #content="{ files, uploadedFiles, messages, removeFileCallback, removeUploadedFileCallback }">
				<PrimevueProgressBar v-bind:value="state.fileupload.progress" v-bind:showValue="false" v-if="hasFiles()" />
				<div class="overflow-y-scroll max-h-15rem pr-3">
					<PrimevueMessage v-bind:key="msg" severity="error" v-for="msg of messages">{{ msg }}</PrimevueMessage>
					<PrimevueFileContent v-bind:pt="fileContentPT" v-bind:files="state.failedFiles" v-bind:badgeValue="$t('failed')" badgeSeverity="danger" v-bind:previewWidth="0" v-bind:templates="state.templates" v-on:remove="onRemoveFailed($event)" />
					<PrimevueFileContent v-bind:pt="fileContentPT" v-bind:files="state.fileupload.files" v-bind:badgeValue="$t('pending')" v-bind:previewWidth="0" v-bind:templates="state.templates" v-on:remove="onRemove($event, removeFileCallback)" v-if="hasFiles()" />
					<PrimevueFileContent v-bind:pt="fileContentPT" v-bind:files="state.fileupload.uploadedFiles" v-bind:badgeValue="$t('completed')" badgeSeverity="success" v-bind:previewWidth="0" v-bind:templates="state.templates" v-on:remove="onRemoveUploaded($event, removeUploadedFileCallback)" />
				</div>
			</template>
		</PrimevueFileUpload>
	</PrimevueDialog>
</template>
