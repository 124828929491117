<script setup>
import { computed as vueComputed, reactive as vueReactive, ref as vueRef, onMounted as vueOnMounted } from 'vue';
import { usePage as inertiaUsePage } from '@inertiajs/vue3';
import { PrimeIcons as primevuePrimeIcons } from 'primevue/api';
import { default as ziggyRoute } from '@/../../vendor/tightenco/ziggy';
import { router as inertiaRouter, Link as InertiaLink } from '@inertiajs/vue3';
import { default as ExhishareApplicationLogo } from '@/Components/exhishare/ApplicationLogo.vue';
import { default as ExhishareFlashMessages } from '@/Components/exhishare/FlashMessages.vue';
import { default as ExhishareLanguageSelector } from '@/Components/exhishare/LanguageSelector.vue';
import { default as ExhishareLoginWidget } from '@/Components/exhishare/LoginWidget.vue';
import { default as ExhishareHistoryDialog } from '@/Components/exhishare/HistoryDialog.vue';

// constants
const modes = {
	'HISTORY': 'history',
	'DEFAULT': 'default',
};

// properties and emits
const props = defineProps({
	'internal': {
		'type': Boolean,
		'default': false,
	},
	'advanced': {
		'type': Boolean,
		'default': false,
	},
	'title': {
		'type': String,
		'default': 'Folder',
	},
	'page': {
		'type': Object,
		'default': {},
	},
});
const emits = defineEmits([
        'update:params',
])

// variables
const state = vueReactive({
	'params': {},
	'mode': modes.DEFAULT,
});
const messages = vueComputed(function () {
	return {
		'flash': inertiaUsePage().props.flash,
		'errors': inertiaUsePage().props.errors,
	};
});
const languages = vueComputed(function () {
	return inertiaUsePage().props.locales;
});

// functions
const isDisplayingHistory = function () {
	return (state.mode == modes.HISTORY);
};
const displayHistory = function (v) {
	state.mode = (v) ? modes.HISTORY : modes.DEFAULT;
	return true;
};

// event handlers
const onMessageClose = function (e) {
	if (e.message._source == 'flash') 
		_.pullAt(inertiaUsePage().props.flash, [ e.message._id ]);
	if (e.message._source == 'errors') 
		_.unset(inertiaUsePage().props.errors, e.message._id);
	return true;
};
const onLanguageSelect = function (e) {
	_.set(state.params, 'lang', e.value);
	emits('update:params', state.params);
	return true;
};
</script>

<template>
	<div class="min-h-screen flex relative lg:static surface-ground" id="layout">
		<ExhishareHistoryDialog v-bind:visible="isDisplayingHistory()" v-bind:page="props.page" v-on:update:visible="displayHistory($event)" />
		<ExhishareFlashMessages class="z-4" v-bind:messages="messages" v-on:message:close="onMessageClose($event)" />
		<div id="app-sidebar-2" class="bg-bluegray-800 hidden lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 select-none w-2">
			<div class="flex flex-column h-screen">
				<div class="flex align-items-center px-5 bg-bluegray-900 flex-shrink-0 h-4rem">
					<InertiaLink class="mt-1" href="/">
						<ExhishareApplicationLogo class="w-full h-full fill-current text-gray-500" />
					</InertiaLink>
				</div>
				<div class="overflow-y-auto mt-3">
					<ul class="list-none p-3 m-0">
						<li>
							<a class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50 transition-duration-150 transition-colors p-ripple">
								<i class="mr-2" v-bind:class="primevuePrimeIcons.HISTORY"></i>
								<span class="font-medium" v-on:click="displayHistory(true)">{{ $t('History') }}</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="mt-auto">
					<hr class="mb-3 mx-3 border-top-1 border-none border-bluegray-600" />
				</div>
			</div>
		</div>
		<div class="min-h-screen flex flex-column relative flex-auto w-10">
			<div class="flex justify-content-between align-items-center px-5 surface-section shadow-2 relative lg:static border-bottom-1 surface-border h-4rem">
				<div class="flex">
				</div>
				<ul class="list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static">
					<li>
						<ExhishareLanguageSelector v-bind:languages="languages" v-on:language:select="onLanguageSelect($event)" />
					</li>
					<li>
						<ExhishareLoginWidget v-bind:advanced="props.advanced" v-bind:internal="props.internal" />
					</li>
				</ul>
			</div>
			<div class="p-5 flex flex-column flex-auto overflow-y-scroll" style="height: 88vh;">
				<slot />
			</div>
		</div>
	</div>
</template>
