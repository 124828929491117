<script setup>
import { reactive as vueReactive, computed as vueComputed } from 'vue';
import { default as PrimevueDialog } from 'primevue/dialog';

// properties and emits
const props = defineProps({
	'settings': {
		'type': Object,
		'default': {},
	},
	'visible': {
		'type': Boolean,
		'default': false,
	},
	'item': {
		'type': Object,
		'default': {
			'type': '',
			'viewlnk': '#',
		},
	},
});
const emits = defineEmits([
	'update:visible',
]);

// variables
const state = vueReactive({
	'visible': vueComputed({
		'get': function () {
			return props.visible;
		},
		'set': function (v) {
			emits('update:visible', v);
			return true;
		},
	}),
});

// functions
const isImage = function (type) {
	return _.includes([ 'JPG', 'JPEG', 'PNG', 'SVG', ], type);
};
</script>

<template>
	<PrimevueDialog class="col-6" v-bind:modal="true" v-bind:maximizable="true" v-bind:header="props.item.name" v-model:visible="state.visible">
		<div class="col-12 inline-block">
			<img class="col-12" alt="" v-bind:src="props.item.viewlnk" v-if="isImage(props.item.type)" />
			<object class="col-12 h-screen" v-bind:data="props.item.viewlnk" v-else>
				<p>{{ $t('Cannot load content :-(') }}</p>
			</object>
		</div>
	</PrimevueDialog>
</template>
