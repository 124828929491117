require('./bootstrap');

import { createApp as vueCreateApp, h as vueH } from 'vue';
import { createInertiaApp as inertiaCreateInertiaApp } from '@inertiajs/vue3';
import { i18nVue as laravelVueI18N, wTrans as laravelvuei18nWTrans } from 'laravel-vue-i18n';
import { usePassThrough as primevueUsePassThrough } from 'primevue/passthrough';
import { default as primeVue } from 'primevue/config/config.esm';
import { default as primevueTailwind } from 'primevue/passthrough/tailwind';
import { default as primevueConfirmationService } from 'primevue/confirmationservice';
import { default as primevueToastService } from 'primevue/toastservice';
import { Link as InertiaLink } from '@inertiajs/vue3'

window.constants = {
	'DEBOUNCE_TIMEOUT': 150,
	'DEFAULT_TIMEOUT': 500,
	'PROGRESS_INTV': 1000,
};
window.formatSize = function (bytes) {
	if (bytes == 0)
		return ' ';
	const prefixes = [ '', 'ki', 'Mi', 'Gi', 'Ti', 'Pi', 'Ei', 'Zi', 'Yi', ];
	const k = 1024, dm = 2, i = Math.floor(Math.log(bytes) / Math.log(k));
	const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
	return laravelvuei18nWTrans(':size :prefixB', {
		'size': formattedSize, 'prefix': prefixes[i],
	}).value;
};

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

const CustomTailwind = primevueUsePassThrough(primevueTailwind, {
}, {
    mergeSections: true,
    mergeProps: false,
});

inertiaCreateInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => require(`./Pages/${name}.vue`),
    progress: {
        color: '#4B5563'
    },
    setup({ el, App, props, plugin }) {
        return vueCreateApp({ render: () => vueH(App, props) })
            .use(plugin)
            .use(laravelVueI18N, {
                resolve: (lang) => import(`../lang/${lang}.json`),
            })
	    .use(primeVue, {
// XXX we'll switch to Tailwind maybe later
//                'unstyled': true,
//                'pt': CustomTailwind
	    })
	    .use(primevueConfirmationService)
	    .use(primevueToastService)
	    .component('inertia-link', InertiaLink)
            .component('router-link', {
                props: [ 'to', 'custom' ],
                template: `<inertia-link v-bind:href="to"><slot/></inertia-link>`,
            })
// XXX import from each page instead
//            .mixin({ methods: { route } })
            .mount(el);
    },
});
