<script setup>
import { computed as vueComputed, reactive as vueReactive } from 'vue';
import { watchDebounced as vueuseWatchDebounced } from '@vueuse/core';
import { wTrans as laravelvuei18nWTrans } from 'laravel-vue-i18n';
import { default as ziggyRoute } from '@/../../vendor/tightenco/ziggy';
import { default as PrimevueDialog } from 'primevue/dialog';
import { default as PrimevueProgressSpinner } from 'primevue/progressspinner';

// constants
const dialogPT = {
	'header': {
		'class': '-mb-1',
	},
};

// properties and emits
const props = defineProps({
	'visible': {
		'type': Boolean,
		'default': false,
	},
});
const emits = defineEmits([
	'update:visible',
]);

// variables
const state = vueReactive({
	'visible': vueComputed({
		'get': function () {
			return props.visible;
		},
		'set': function (v) {
			state.transferred = 0;
			emits('update:visible', v);
			return true;
		},
	}),
	'params': ziggyRoute().params,
	'transferred': 0,
});

// functions
const displaySize = function () {
	return formatSize(state.transferred);
};
const progressRoute = function (params) {
	return ziggyRoute('share.progress', { 'id': params.id, });
};
const updateSpinner = function () {
	return axios.get(progressRoute(state.params)).then(function (res) {
		state.transferred = res.data.progress.dl.bytes;
		if (res.data.progress.complete) {
			state.visible = false;
			return true;
		}
		setTimeout(updateSpinner, constants.DEFAULT_TIMEOUT);
		return false;
	});
};

// page events
vueuseWatchDebounced(function () {
	return state.visible;
}, function (cur, old) {
	if (!cur)
		return false;
	return updateSpinner();
}, {
	'deep': true,
	'debounce': constants.DEBOUNCE_TIMEOUT,
	'maxWait': constants.DEFAULT_TIMEOUT,
});
</script>

<template>
	<PrimevueDialog v-bind:pt="dialogPT" v-bind:closable="false" v-bind:header="$t('Preparing files...')" v-bind:modal="true" v-model:visible="state.visible">
		<div class="flex flex-column align-items-center">
			<PrimevueProgressSpinner />
			<div>{{ displaySize() }}</div>
		</div>
	</PrimevueDialog>
</template>
