<script setup>
import { ref as vueRef, reactive as vueReactive } from 'vue';
import { PrimeIcons as primevuePrimeIcons } from 'primevue/api';
import { default as PrimevueBreadcrumb } from 'primevue/breadcrumb';
import { default as PrimevueButton } from 'primevue/button';
import { Link as InertiaLink } from '@inertiajs/vue3';

// properties and emits
const props = defineProps({
	'pathlinks': {
		'type': Array,
		'default': [],
	},
});
const emits = defineEmits([
]);

// variables
const state = vueReactive({
});
const home = vueRef({
	'icon': primevuePrimeIcons.HOME,
});

// functions
const has = function (obj, path) {
	return _.has(obj, path);
};
</script>

<template>
	<PrimevueBreadcrumb class="m-2 overflow-hidden white-space-nowrap" v-bind:home="home" v-bind:model="props.pathlinks">
		<template #item="{item}">
			<InertiaLink class="p-button-link" v-bind:href="item.to" v-if="has(item, 'to')">
				<PrimevueButton class="p-0" v-bind:class="item.icon" v-bind:link="true">{{ item.label }}</PrimevueButton>
			</InertiaLink>
			<span v-bind:class="item.icon" v-else>{{ item.label }}</span>
		</template>
	</PrimevueBreadcrumb>
</template>

